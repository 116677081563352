import { cloneElement, type PropsWithChildren } from 'react';
import { isElement, Tooltip } from '@mantine/core';

import MercuryModal from '@/components/content/modal/Modal';
import NotificationSystemModal from './NotificationSystemModal';

import { useNotificationSystem } from './useNotificationSystem';
import { useGetNotificationRules } from '@/features/usersettings/services/notificationRules';
import { useMercuryPermissions } from '@/hooks/useMercuryPermissions';

import { FEATURES } from '@/constants/permissions';
import type { NotificationSystemFormFields } from './types';

interface NotificationSystemProps {
  data: Partial<NotificationSystemFormFields>;
  id?: number;
}

const RULES_LIMIT = 5;

const NotificationSystem = ({
  data,
  id,
  children,
}: PropsWithChildren<NotificationSystemProps>) => {
  const {
    onToggle,
    opened,
    onSubmit,
    isSubmitting,
  } = useNotificationSystem(id);
  const hasNotificationPermissions = useMercuryPermissions().hasAccess(FEATURES.notifications);
  const rules = useGetNotificationRules((data) => data.total < RULES_LIMIT, hasNotificationPermissions);

  if (!isElement(children)) {
    throw new Error(
      // eslint-disable-next-line max-len
      'NotificationSystem.Target component children should be an element or a component that accepts ref. Fragments, strings, numbers and other primitive values are not supported',
    );
  }

  if (!hasNotificationPermissions) {
    return null;
  }

  const canUse = (rules.isSuccess && rules.data) || rules.isError;

  return (
    <>
      <Tooltip
        label={`You reached the rule limit of ${RULES_LIMIT}`}
        disabled={rules.isPending || canUse}
        maw={220}
        multiline
      >
        {cloneElement(children as any, {
          onClick: onToggle,
          disabled: !canUse,
          'data-loaded': !rules.isPending,
        })}
      </Tooltip>

      <MercuryModal
        opened={opened}
        onClose={onToggle}
        title="Set Your Rule"
        size="xl"
        centered
      >
        <NotificationSystemModal
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          closeModal={onToggle}
          data={data}
          submitText={id ? 'Update Rule' : 'Set Rule'}
        />
      </MercuryModal>
    </>
  );
};

export default NotificationSystem;
