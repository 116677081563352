import { useRateLimit } from '@/context/RateLimitContext';
import { FetchError } from '@/types/api';
import { MutationCache, QueryCache, QueryClient, QueryObserverOptions } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const queryDefaultOptions: QueryObserverOptions = {
  retry: 0,
  refetchOnWindowFocus: false,
  refetchOnReconnect: 'always',
  staleTime: 60 * 5000, // 5 minutes,
  networkMode: 'always',
  queryKey: [],
};

export const queryClient = () => {
  const navigate = useNavigate();
  const { enableRateLimit } = useRateLimit();

  const handleUnauthorized = (avoidAuthRedirection: unknown, error: FetchError) => {
    if (avoidAuthRedirection) {
      return;
    }

    if (error.code === 429) {
      const retryAfter = error.headers?.get('retry-after');

      enableRateLimit(retryAfter ? +retryAfter : 0);
    }

    if (error.code === 401) {
      navigate('/login', {
        state: {
          modal_title: 'Your session expired',
          modal_text: 'Your session expired due to inactivity. Please login again to keep using mercury features.',
        },
      });
    }
  };

  return () => new QueryClient({
    mutationCache: new MutationCache({
      onError: (error, variables, context, mutation) => {
        handleUnauthorized(mutation.meta?.avoidAuthRedirection, error);
      },
    }),
    queryCache: new QueryCache({
      onError: (error, query) => {
        handleUnauthorized(query.meta?.avoidAuthRedirection, error);
      },
    }),
    defaultOptions: {
      queries: queryDefaultOptions,
    },
  });
};
