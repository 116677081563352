import type { ChangeEvent } from 'react';
import { NativeSelect } from '@mantine/core';
import { useDateFormatter } from 'react-aria';
import type { RangeCalendarState } from 'react-stately';

import classes from './DatePicker.module.css';

interface MonthDropdownProps {
  state: RangeCalendarState;
}

const MonthDropdown = ({ state }:MonthDropdownProps) => {
  const months = [];
  const formatter = useDateFormatter({
    month: 'long',
    timeZone: state.timeZone,
  });

  // Format the name of each month in the year according to the
  // current locale and calendar system. Note that in some calendar
  // systems, such as the Hebrew, the number of months may differ
  // between years.
  const numMonths = state.focusedDate.calendar.getMonthsInYear(state.focusedDate);
  for (let i = 1; i <= numMonths; i += 1) {
    const date = state.focusedDate.set({ month: i });
    months.push(formatter.format(date.toDate(state.timeZone)));
  }

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(e.target.value);
    const date = state.focusedDate.set({ month: value });
    state.setFocusedDate(date);
  };

  return (
    <NativeSelect
      aria-label="Months"
      value={state.focusedDate.month}
      onChange={onChange}
      data={months.map((month, i) => ({ value: String(i + 1), label: month }))}
      size="xs"
      classNames={{ input: classes.datePickerSelect }}
    />
  );
};

export default MonthDropdown;
