import { useQuery } from '@tanstack/react-query';
import { getMutation } from '@/lib/react-query/mutate';

import { fetchData } from '@/lib/fetch';
import { authKeys } from './keys';

import type { RegisterFormFields, TokenInfo } from '../types';

export const useGetTokenInfoMutation = () => (
  getMutation<TokenInfo>({
    endpoint: 'tokeninfo',
    requestOptions: {
      error: 'There was a technical problem, please try again',
    },
  })
);

export const useGetUserInfoByToken = (token: string) => (
  useQuery<RegisterFormFields & { phone: number; external_auth: boolean; }>({
    queryKey: authKeys.userInfoToken(token),
    queryFn: () => fetchData({
      endpoint: `auth/token/userinfo/${token}`,
      error: 'Couldn\'t verify user',
    }),
    meta: {
      avoidAuthRedirection: true,
    },
  })
);
