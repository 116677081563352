import { Fragment } from 'react';
import { Group, Text, Tooltip } from '@mantine/core';
import { useFieldArray } from 'react-hook-form';

import MercuryNativeSelect from '@/components/content/form/components/NativeSelect';
import MercuryTextInput from '@/components/content/form/components/TextInput';

import type { FieldsetProps } from '../types';

import InfoIcon from '@/assets/icons/content/info.svg';

interface ActionsProps extends FieldsetProps {
}

const Actions = ({
  register,
  control,
  formState,
}: ActionsProps) => {
  const { fields } = useFieldArray({
    control,
    name: 'actions',
  });

  return (
    <fieldset>
      <legend>
        <Group justify="space-between" align="center" component="span">
          <span>

            Actions<sup>*</sup>

            <Text size="xs" span display="block">Define actions when trigger conditions are met.</Text>
          </span>

          <Tooltip label="Beta version is limited to sending emails">
            <span>
              <InfoIcon width={17} height={17} />
            </span>
          </Tooltip>
        </Group>
      </legend>

      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <MercuryNativeSelect
            register={register}
            name={`actions.${index}.type` as any}
            label="Type"
            // HARDCODED for now since we only have one action but will move to a choices endpoint
            data={[{ label: 'Send email', value: 'notify_email' }]}
            error={formState.errors?.actions?.[index]?.root?.message}
            size="xs"
          />

          <MercuryTextInput
            register={register}
            name={`actions.${index}.values.email` as any}
            label="Address"
            description="Write email addresses, comma separated"
            error={formState.errors?.actions?.[index]?.values?.email?.message}
            size="xs"
            displayError
          />

          <MercuryTextInput
            register={register}
            name={`actions.${index}.values.subject` as any}
            label="Email Subject"
            size="xs"
          />
        </Fragment>
      ))}
    </fieldset>
  );
};

export default Actions;
