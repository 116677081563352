import Button from '../button/Button';

import { useToast } from '@/hooks/useToast';
import { useAuth } from '@/hooks/useAuth';
import { postMutation } from '@/lib/react-query/mutate';

const UpsellAction = () => {
  const { tokenInfo } = useAuth();
  const { createToast } = useToast();

  const { mutate: sendEmail, isPending: isSending } = postMutation({
    endpoint: 'mercury/supportemail',
  });

  const onRequestUpgrade = () => {
    sendEmail(
      {
        subject: 'Upgrade to Premium',
        content: `
          The following user has requested an upgrade to premium:

          User: ${tokenInfo.sub}
          User ID: ${tokenInfo.user_id}
          Company: ${tokenInfo.client_name}
        `,
      },
      {
        onSuccess: () => {
          createToast('Your request has been sent', 'success');
        },
      },
    );
  };

  return (

    <Button disabled={isSending} onClick={onRequestUpgrade}>
      Upgrade to premium
    </Button>
  );
};

export default UpsellAction;
