import {
  createContext, PropsWithChildren, useContext, useEffect, useMemo, useState,
} from 'react';

import Timeout from '@/features/timeout/pages/Timeout';

import { ping, PingResponse } from '@/services/ping';
import { usePermanentMessage } from '@/hooks/usePermanentMessage';
import { useDrawer } from '@/hooks/useDrawer';

const STATUSES = {
  401: 'timeout',
  500: 'server problems',
  200: 'broken connection',
  default: 'network problems',
} as const;

type Statuses = typeof STATUSES[keyof typeof STATUSES];

type SessionContextValue = {
  startSession: VoidFunction;
  stopSession: VoidFunction;
};

export const SessionContext = createContext<SessionContextValue | null>(null);

const HEARTBEAT_INTERVAL = 20000;
const MAX_HEARTBEAT_INTERVAL = 120000;
const MAX_SUCCESSIVE_BEFORE_BACKOFF = 3;

const MessageProvider = (status: Statuses): { message: string } => ({
  [STATUSES[401]]: {
    message: 'timeout',
  },
  [STATUSES[500]]: {
    message: 'Our server is experiencing some problems. Please try again later',
  },
  [STATUSES[200]]: {
    message: 'We have lost connection to the server and Mercury wont respond',
  },
  [STATUSES.default]: {
    message: 'You seem to be experiencing some problems with connection. Please check your internet connection.',
  },
}[status]);

export const SessionContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [refetchInterval, setFeretchInterval] = useState(HEARTBEAT_INTERVAL);
  const [heartbeatEnabled, setHeartbeatEnabled] = useState(false);
  const [errorStep, setErrorStep] = useState(0);
  const [succesiveResponse, setSuccesiveResponse] = useState(0);
  const [status, setStatus] = useState('alive');
  const { createMessage } = usePermanentMessage();
  const { closeDrawer } = useDrawer();

  const getStatus = (code: number): Statuses => {
    switch (code) {
      case 401:
        return STATUSES[401];
      case 500:
        return STATUSES[500];
      case 200:
        return STATUSES[200];
      default:
        return STATUSES.default;
    }
  };

  const backoffPing = () => {
    setFeretchInterval((ri) => {
      const addedInterval = ri + HEARTBEAT_INTERVAL;

      return addedInterval > MAX_HEARTBEAT_INTERVAL ? MAX_HEARTBEAT_INTERVAL : addedInterval;
    });
  };

  const restorePing = () => setFeretchInterval(HEARTBEAT_INTERVAL);
  const stopSession = () => setHeartbeatEnabled(false);
  const startSession = () => setHeartbeatEnabled(true);
  const resetSteps = () => setErrorStep(0);
  const addErrorStep = () => setErrorStep((step) => step + 1);

  const isStepError = (step: number) => errorStep === step;

  const handleHearbeatInterval = (newStatus: string) => {
    if (status === newStatus) {
      if ((succesiveResponse + 1) >= MAX_SUCCESSIVE_BEFORE_BACKOFF) {
        setSuccesiveResponse(0);
        backoffPing();
        return;
      }

      setSuccesiveResponse((sr) => sr + 1);
      return;
    }

    setSuccesiveResponse(0);
    restorePing();
  };

  const onError = (code: number) => {
    const newStatus = getStatus(code);

    handleHearbeatInterval(newStatus);

    if (isStepError(0) && newStatus !== 'timeout') {
      setStatus('trying to reconnect');
      createMessage(
        'We seem to be experiencing some problems. Some parts of Mercury might not work',
        'warning',
      );
      addErrorStep();
      return;
    }

    if (newStatus === 'timeout') {
      stopSession();
      closeDrawer();
    }

    if (isStepError(1)) {
      createMessage(MessageProvider(newStatus).message, 'error');
    }

    addErrorStep();
    setStatus(newStatus);
  };

  const onSuccess = (data: PingResponse) => {
    if (data.body === 'pong') {
      if (status !== 'alive') {
        createMessage('Reconnected', 'success', { timeout: 5000 });
        setSuccesiveResponse(0);
        setStatus('alive');
        resetSteps();
        restorePing();
      } else {
        handleHearbeatInterval('alive');
      }
    } else {
      onError(200);
    }
  };

  const pingQuery = ping({
    refetchInterval,
    enabled: heartbeatEnabled,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (pingQuery.isSuccess) {
      onSuccess(pingQuery.data);
    }
  }, [pingQuery.isSuccess]);

  useEffect(() => {
    if (pingQuery.isError) {
      onError(pingQuery.error.code);
    }
  }, [pingQuery.isError]);

  const contextValue = useMemo(
    () => ({ startSession, stopSession, status }),
    [status],
  );

  const displayTimeout = status === STATUSES[401];
  const displayChildren = !displayTimeout;

  return (
    <SessionContext.Provider value={contextValue}>
      {displayTimeout && <Timeout />}
      {displayChildren && children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const sessionContext = useContext(SessionContext);

  if (!sessionContext) {
    throw new Error('Session Context is not initialized. This hook must be used inside a SessionContextProvider');
  }

  return sessionContext;
};
