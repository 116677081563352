import { Flex } from '@mantine/core';

import CoinIcon from '@/assets/icons/topbar/coin.svg';

import classes from './Topbar.module.css';

interface CoinProps {
  value: number;
  lowValue: number;
}

const Coin = ({
  value,
  lowValue,
}: CoinProps) => (
  <Flex align={{ base: 'flex-start', s: 'center' }} direction={{ base: 'column', s: 'row' }} gap={10}>
    <Flex align="center">
      <CoinIcon className={classes.coinIcon} />

      <span className={`${classes.coinValue} ${value <= lowValue ? classes.low : ''}`}>
        {+value.toFixed(2)}
      </span>
    </Flex>
  </Flex>
);

export default Coin;
