import IocList from '../../issue/modules/IocList';
import Markdown from '@/components/content/markdown/Markdown';
import IncidentActions from '../components/IncidentActions';
import IncidentState from '../components/IncidentState';

import { formatIsoDate } from '@/utils/date';
import { createHelper } from '../../issue/utils/helper';
import { getValues, objectHasValues } from '@/utils';

import type { Module } from '../../issue/types/modulesv2';
import type { IncidentListRecord } from '../types';
import Priority from '@/components/content/priority/Priority';

const helper = createHelper<IncidentListRecord>();

export const incidentModalModules = ({
  hideActions,
}: { hideActions?: boolean }): Array<Module<IncidentListRecord>> => [
    {
      id: 'overview',
      direction: { base: 'column', s: 'row' },
      wrap: 'wrap',
      submodules: [
        helper.accessor((data) => data.created_at, {
          title: 'Created:',
          direction: { base: 'column', s: 'row' },
          align: { sm: 'center' },
          element: ({ getValue }) => formatIsoDate(getValue()),
          scroll: false,
        }),
        helper.accessor((data) => ({
          state: data.client_state?.[0],
          hasTakedownRequests: data.rfis?.filter((rfi) => rfi.name.includes('Takedown Request')).length > 0,
        }), {
          title: 'Status:',
          direction: { base: 'column', s: 'row' },
          align: { sm: 'center' },
          element: ({ getValue }) => <IncidentState {...getValue()} />,
          scroll: false,
        }),
        helper.accessor((data) => data?.client_state?.[0]?.assignee, {
          title: 'Asignee:',
          direction: { base: 'column', s: 'row' },
          align: { sm: 'center' },
          element: ({ getValue }) => getValues(getValue())[0] || 'Unassigned',
          scroll: false,
        }),
        helper.accessor((data) => data.priority, {
          title: 'Priority:',
          direction: { base: 'column', s: 'row' },
          align: { sm: 'center' },
          element: ({ getValue, id }) => <Priority priority={getValue()} id={+id} />,
          scroll: false,
        }),
        helper.accessor((data) => data.category, {
          title: 'Category:',
          direction: { base: 'column', s: 'row' },
          align: { sm: 'center' },
          scroll: false,
        }),
        helper.accessor((data) => data.subcategory, {
          title: 'Subcategory:',
          direction: { base: 'column', s: 'row' },
          align: { sm: 'center' },
          scroll: false,
        }),
        helper.accessor((data) => data.sources, {
          title: 'Source:',
          direction: { base: 'column', s: 'row' },
          align: { sm: 'center' },
          element: ({ getValue }) => getValues(getValue())[0],
          scroll: false,
        }),
        helper.accessor((data) => data.updated_at, {
          title: 'Last Update:',
          direction: { base: 'column', s: 'row' },
          align: { sm: 'center' },
          element: ({ getValue }) => formatIsoDate(getValue()),
          scroll: false,
        }),
      ],
    },
    {
      submodules: [
        helper.accessor((data) => data.iocs, {
          title: 'IoC List',
          canCopy: true,
          element: ({ getValue }) => <IocList iocs={getValue()} />,
        }),
      ],
    },
    {
      removeBackground: true,
      hide: hideActions,
      noPrint: true,
      submodules: [
        helper.accessor((data) => data, {
          title: 'Actions',
          direction: { base: 'column', s: 'row' },
          justify: 'space-between',
          element: ({ getValue }) => {
            const { id, client_state, name, rfis, takedowns } = getValue();

            return (
              <IncidentActions
                issueId={id}
                clientState={client_state?.[0]}
                name={name}
                hasTakedownRequests={(
                  rfis?.filter((rfi) => rfi.name.includes('Takedown Request')).length > 0
                  || objectHasValues(takedowns)
                )}
                asButton
              />
            );
          },
          scroll: false,
        }),
      ],
    },
    {
      submodules: [
        helper.accessor((data) => data.description, {
          title: 'Description',
          element: ({ getValue }) => <Markdown text={getValue()} disallowedElements={['img']} />,
        }),
      ],
    },
  ];
