import { useRef } from 'react';
import {
  ActionIcon, Group, Input, Popover, Stack,
} from '@mantine/core';
import { AriaDateRangePickerProps, DateValue, useDateRangePicker } from 'react-aria';
import { useDateRangePickerState } from 'react-stately';

import RangeCalendar from './RangeCalendar';
import DateField from './DateField';

import CalendarIcon from '@/assets/icons/content/calendar.svg';
import RightArrow from '@/assets/icons/content/right-arrow.svg';

import type { Presets, RangeValue } from './types';

import classes from './DatePicker.module.css';

interface DatePickerProps extends Omit<AriaDateRangePickerProps<DateValue>, 'value'> {
  presets?: Presets;
  onChange: ((value: string | RangeValue) => void);
  value: string | RangeValue | null;
  disableRelativeDates?: boolean;
}

const DatePicker = ({
  presets,
  onChange,
  value,
  disableRelativeDates = false,
  ...datePickerProps
}: DatePickerProps) => {
  const nullOrVal = typeof value === 'undefined' ? null : value;
  const rangeValue = typeof nullOrVal === 'string' ? presets?.[nullOrVal].rangeDateValue() : nullOrVal;
  const ref = useRef(null);
  const datePickerState = useDateRangePickerState({
    onChange,
    value: rangeValue,
  });

  const {
    groupProps,
    startFieldProps,
    endFieldProps,
    calendarProps,
    errorMessageProps,
  } = useDateRangePicker(
    {
      onChange,
      value: rangeValue,
      ...datePickerProps,
    },
    datePickerState,
    ref,
  );

  return (
    <Popover
      classNames={{ dropdown: classes.datePickerDropdown }}
      opened={datePickerState.isOpen}
      onChange={datePickerState.setOpen}
      position="bottom-start"
    >
      <Popover.Target>
        <Stack gap={5}>
          <Input
            component="div"
            styles={{
              input: {
                height: 'auto',
              },
            }}
            error={datePickerState.isInvalid}
          >
            <Group
              align="center"
              justify="space-between"
              wrap="nowrap"
              ref={ref}
              {...groupProps}
            >
              <Group gap={5}>
                <DateField {...startFieldProps} />
                <RightArrow />
                <DateField {...endFieldProps} />
              </Group>

              <ActionIcon
                variant="transparent"
                onClick={() => datePickerState.setOpen(!datePickerState.isOpen)}
              >
                <CalendarIcon />
              </ActionIcon>
            </Group>
          </Input>

          {datePickerState.isInvalid && (
            <span {...errorMessageProps} className={classes.datePickerError}>
              Start date must be before end date.
            </span>
          )}
        </Stack>
      </Popover.Target>

      <Popover.Dropdown>
        <RangeCalendar
          rangeCalendarProps={calendarProps}
          presets={presets}
          onChange={onChange}
          disableRelativeDates={disableRelativeDates}
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export default DatePicker;
