import { useAuth } from '@/hooks/useAuth';

import type { NormalizedRedmineField } from '../catalogs/types';

interface RiskIndicatorsProps {
  context: NormalizedRedmineField;
  indicator: 'relevant_technologies' | 'relevant_locations' | 'relevant_industry_sectors';
}

export const useRiskIndicator = ({
  context,
  indicator,
}: RiskIndicatorsProps) => {
  const auth = useAuth();
  const userContext = auth.userInfo?.[indicator] || [];

  function getRelevantContext() {
    if (!userContext || !context.length) return [];

    const contextIds = new Set(context.map((item) => +item.id));

    const hasMatchingItem = (items: typeof userContext | null) => {
      if (!items) return false;

      return items.some((item) => contextIds.has(+item.id));
    };

    return userContext
      .filter((userContextItem) => (
        contextIds.has(+userContextItem.id)
        || hasMatchingItem(userContextItem.children)
        || hasMatchingItem(userContextItem.parents)
      ))
      .map((userContextItem) => userContextItem.name);
  }

  const relevantContext: Array<string> = getRelevantContext();

  return {
    hasIndicator: relevantContext.length > 0,
    relevantContext,
  };
};
