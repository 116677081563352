import { Flex } from '@mantine/core';

import Acknowledge from '../../clientstate/components/Acknowledge';
import Resolve from '../../clientstate/components/Resolve';
import RequestTakedown from './RequestTakedown';
import PrintButton from '@/components/content/button/PrintButton';

import { useIssueState } from '../hooks/useIssueState';
import { incidentsKeys, INCIDENTS_ENDPOINTS } from '../services/keys';
import { unifiedApiKeys } from '@/services/keys';
import { notificationsKeys } from '../../notifications/services/keys';

import { OIncidentsStatus } from '../types';
import type { Schema } from '@/types/mercury-data-types/unifiedapi';

interface IncidentActionsProps {
  issueId: number;
  asButton?: boolean;
  loading?: boolean;
  disabled?: boolean;
  print?: boolean;
  name?: string;
  clientState?: Schema<'IssueClientResponse'>;
  hasTakedownRequests?: boolean;
}

const IncidentActions = ({
  asButton,
  issueId,
  loading,
  disabled,
  print,
  name,
  clientState,
  hasTakedownRequests,
}: IncidentActionsProps) => {
  const [status, previousStatus] = useIssueState(clientState, hasTakedownRequests);
  const isAcknowledged = clientState?.status === OIncidentsStatus.Acknowledged;
  const isResolved = clientState?.status === OIncidentsStatus.Resolved;

  const nextStatus = previousStatus === OIncidentsStatus.Acknowledged
    ? OIncidentsStatus.Acknowledged : OIncidentsStatus.New;

  return (
    <Flex
      align="center"
      justify="center"
      gap={10}
      flex="1"
      direction={{
        base: asButton ? 'column' : 'row',
        s: 'row',
      }}
    >
      {print && (
        <PrintButton documentTitle={['QuoIntelligence', `${issueId}`, name || '']} asButton />
      )}

      {status?.toLowerCase() !== OIncidentsStatus.Pending && status?.toLowerCase() !== OIncidentsStatus.Resolved && (
        <Acknowledge
          issueId={issueId}
          isAcknowledged={isAcknowledged}
          resource={INCIDENTS_ENDPOINTS.incidents}
          previousStatus={nextStatus}
          invalidate={[
            notificationsKeys.notifications(),
            unifiedApiKeys.ticket(INCIDENTS_ENDPOINTS.incidents, issueId),
            incidentsKeys.incidentsGfx(),
            incidentsKeys.incidentsTable(),
            incidentsKeys.incidentsDashboard(),
          ]}
          loading={loading}
          disabled={disabled}
          asButton={asButton}
        />
      )}

      <Resolve
        issueId={issueId}
        isResolved={isResolved}
        resource={INCIDENTS_ENDPOINTS.incidents}
        previousStatus={nextStatus}
        invalidate={[
          unifiedApiKeys.ticket(INCIDENTS_ENDPOINTS.incidents, issueId),
          incidentsKeys.incidentsGfx(),
          incidentsKeys.incidentsTable(),
          incidentsKeys.incidentsDashboard(),
        ]}
        loading={loading}
        disabled={disabled}
        asButton={asButton}
      />

      <RequestTakedown
        issueId={issueId}
        disabled={disabled}
        loading={loading}
        asButton={asButton}
      />
    </Flex>
  );
};

export default IncidentActions;
