import { Box } from '@mantine/core';

// Components
import TwoFAModal from './TwoFAModal';
import Button from '@/components/content/button/Button';

// Hooks
import { useModal } from '@/hooks/useModal';
import { useTfa } from '../../hooks/useTfa';
import { useAuth } from '@/hooks/useAuth';

interface TFAProps {
  enabled: boolean;
  createMessage: (msg: string, options: any) => void;
}

const TwoFA = ({ enabled, createMessage }: TFAProps) => {
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const { userInfo, setUserInfo } = useAuth();

  const {
    generateTFA,
    activateTFA,
    deactivateTFA,
    TFAData,
    disableButton,
  } = useTfa({
    onActivateSuccess: (tfa) => {
      setUserInfo({ two_factor_enabled: tfa });
      createMessage('2FA Successfully activated', { type: 'success' });
      closeModal();
    },
    onDeactivateSuccess: (tfa) => {
      setUserInfo({ two_factor_enabled: tfa });
      createMessage('2FA Successfully deactivated', { type: 'success' });
      closeModal();
    },
    onGenerateSuccess: () => {
      openModal();
    },
    username: userInfo.email,
  });

  const button = enabled ? { text: 'Deactivate 2FA', cb: openModal } : { text: 'Activate 2FA', cb: generateTFA };
  const tfaAction = enabled ? deactivateTFA : activateTFA;

  return (
    <Box w="100%">
      <Button
        onClick={button.cb}
        disabled={disableButton}
        id="tfa-button"
      >
        {button.text}
      </Button>

      {(TFAData || enabled) && (
        <Modal {...modalProps} title={`${enabled ? 'Deactivate' : 'Activate'} Two-Factor Authentication`} size="500px">
          <TwoFAModal enabled={enabled} data={TFAData} tfaAction={tfaAction} disableAction={disableButton} />
        </Modal>
      )}
    </Box>
  );
};

export default TwoFA;
