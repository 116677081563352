import { Link } from 'react-router-dom';

import Coin from './Coin';

import { LOW_QUOINS } from '../../../../constants';
import { useAuth } from '@/hooks/useAuth';
import { Transition } from '@mantine/core';

const CoinMenuItem = () => {
  const { userInfo, userInfoQuery } = useAuth();

  return (
    <Transition
      mounted={userInfoQuery.isSuccess}
      transition="slide-left"
      duration={300}
      timingFunction="ease"
    >
      {(styles) => (
        <Link to="/settings" style={styles}>
          <Coin value={userInfo?.quoins?.available} lowValue={LOW_QUOINS} />
        </Link>
      )}
    </Transition>
  );
};

export default CoinMenuItem;
