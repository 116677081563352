import { Box } from '@mantine/core';

// Components
import PasswordChangeModal from './PasswordChangeModal';
import Button from '@/components/content/button/Button';

// Hooks
import { useModal } from '@/hooks/useModal';
import { useAuth } from '@/hooks/useAuth';

// Api
import { usePasswordChangeMutation } from '../../services/passwordChange';
import { OnSubmitFunc } from '@/types/form';

// Types
import type { PasswordChangeFields } from '../../types';

const PasswordChange = ({ createMessage }: { createMessage: (msg: string, options: any) => void }) => {
  const { tokenInfo } = useAuth();
  const { isPending: isSubmitting, mutate: changePassword } = usePasswordChangeMutation();
  const { Modal, modalProps, openModal, closeModal } = useModal();

  const onSubmit: OnSubmitFunc<PasswordChangeFields> = (formData, setGeneralError) => {
    const passwordPayload = {
      username: tokenInfo.sub,
      password: formData.currentPassword,
      new_password: formData.newPassword,
    };

    changePassword(passwordPayload, {
      onSuccess: () => {
        createMessage('Password changed successfully', { type: 'success' });
        closeModal();
      },
      onError: (error) => {
        setGeneralError(error);
      },
    });
  };

  return (
    <Box w="100%">
      <Button
        onClick={openModal}
        id="passchange-button"
      >
        Password Change
      </Button>

      <Modal {...modalProps} title="Change password" size="500px">
        <PasswordChangeModal onSubmit={onSubmit} isSubmitting={isSubmitting} />
      </Modal>
    </Box>
  );
};

export default PasswordChange;
