import { useNavigate } from 'react-router-dom';

import { useAdminRedirect } from '../hooks/useAdminRedirection';
import { useQueryActions } from '../../../hooks/useQueryActions';

import { getMutation } from '@/lib/react-query/mutate';

export const useLogout = () => {
  const navigate = useNavigate();
  const { clear } = useQueryActions();
  const { returnToMercuryOrigin, isInAdminDomain } = useAdminRedirect();

  return {
    mutation: getMutation({
      endpoint: 'logout',
      mutationOptions: {
        onSuccess: clear,
      },
    }),
    clientLogout: () => {
      if (isInAdminDomain()) {
        returnToMercuryOrigin('login');
        return;
      }

      navigate('/login', { replace: true, state: { avoidPrecheck: true } });
    },
  };
};
