import { ActionIcon, Tooltip } from '@mantine/core';
import type { QueryKey } from '@tanstack/react-query';

import Button from '@/components/content/button/Button';

import { useQueryActions } from '@/hooks/useQueryActions';
import { useToast } from '@/hooks/useToast';
import { postMutation } from '@/lib/react-query/mutate';

import ResolveIcon from '@/assets/icons/content/resolved.svg';
import { useAuth } from '@/hooks/useAuth';

interface ResolveProps {
  issueId: number;
  isResolved: boolean;
  asButton?: boolean;
  loading?: boolean;
  disabled?: boolean;
  invalidate?: Array<QueryKey>;
  resource: string;
  previousStatus: string;
}

const Resolve = ({
  issueId,
  isResolved,
  asButton,
  loading,
  disabled,
  resource,
  invalidate = [],
  previousStatus,
}: ResolveProps) => {
  const actions = useQueryActions();
  const auth = useAuth();

  const mutation = postMutation({
    endpoint: `v0${resource}${issueId}`,
    method: 'PATCH',
  });

  const { createToast } = useToast();

  const disableResolved = disabled || loading || mutation.isPending;
  const isLoading = loading || mutation.isPending;
  const text = isResolved ? 'Unresolve' : 'Resolve';

  const onResolve = () => {
    mutation.mutate({
      client_state: [
        {
          client_id: auth.tokenInfo.client_access_id,
          status: isResolved ? previousStatus : 'resolved',
        },
      ],
    }, {
      onSuccess: () => {
        // Replication is async and takes some time to complete
        setTimeout(() => {
          actions.invalidateQueries(invalidate, { type: 'all' });
        }, 500);
      },
      onError: (error) => {
        createToast(error.message, 'error');
      },
    });
  };

  return (
    <>
      {asButton ? (
        <Button
          onClick={onResolve}
          color="var(--quo-blue-75)"
          size="sm"
          loading={isLoading}
          disabled={disableResolved}
          rightSection={<ResolveIcon width={20} height={20} />}
        >
          {text}
        </Button>
      ) : (
        <Tooltip label={text}>
          <ActionIcon
            size={24}
            variant="subtle"
            aria-label={text}
            disabled={disableResolved}
            loaderProps={{ size: 20 }}
            loading={isLoading}
            onClick={onResolve}
          >
            <ResolveIcon />
          </ActionIcon>
        </Tooltip>
      )}
    </>
  );
};

export default Resolve;
