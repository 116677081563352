import { useTokenInfo, useUserInfo } from '@/features/auth/hooks/useUserInfo';
import { useAuthInfoMutation } from '@/features/auth/hooks/useAuthInfoMutation';

import type { TokenInfo, UserInfo } from '@/features/auth/types';

export const useAuth = () => {
  const {
    data: userInfo,
    ...userInfoQuery
  } = useUserInfo();
  const {
    data: tokenInfo,
    ...tokenInfoQuery
  } = useTokenInfo();

  const {
    setUserInfo,
    setTokenInfo,
  } = useAuthInfoMutation();

  const getContextData = (
    context: 'relevant_industry_sectors' | 'relevant_technologies' | 'relevant_locations',
  ) => {
    const contextData = (userInfo as UserInfo)?.[context] || [];
    const result: { ids: Array<number>; names: Array<string> } = { ids: [], names: [] };

    const getFlat = (node: typeof contextData[number]) => {
      result.ids.push(node.id);
      result.names.push(node.name);

      if (node.children) {
        node.children.forEach((child) => getFlat(child));
      }
      if (node.parents) {
        node.parents.forEach((parent) => getFlat(parent));
      }
    };

    contextData.forEach((item) => getFlat(item));
    return result;
  };

  return {
    userInfo: userInfo as UserInfo,
    tokenInfo: tokenInfo as TokenInfo,
    userInfoQuery,
    tokenInfoQuery,
    setUserInfo,
    setTokenInfo,
    getContextData,

  };
};
