import { useEffect, useState } from 'react';
import { Collapse, Tooltip, TypographyStylesProvider } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import Button from '@/components/content/button/Button';

import { useNotificationPreview } from '../services/notification';

import { sanitize } from '@/utils/sanitize';
import type { NotificationSystemFormFields } from '../types';

import EyeIcon from '@/assets/icons/content/eye.svg';

import classes from '../NotificationSystem.module.css';

interface PreviewProps {
  fields: NotificationSystemFormFields;
  enabled: boolean;
}

const Preview = ({
  fields,
  enabled,
}: PreviewProps) => {
  const { mutate: onOpenPreview, isPending: isOpening } = useNotificationPreview();
  const [opened, { toggle }] = useDisclosure(false);
  const [previewContent, setPreviewContent] = useState<string | null>(null);
  const [debouncedFields, setDebouncedFields] = useState(fields);

  // Debounce function for delaying the mutation trigger
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFields(fields);
    }, 500); // 500ms debounce delay

    return () => clearTimeout(handler);
  }, [fields]);

  useEffect(() => {
    if (opened && debouncedFields) {
      onOpenPreview(
        { ...debouncedFields, schedules: [debouncedFields.schedules] },
        {
          onSuccess: (newData) => {
            setPreviewContent(newData); // Save the fetched HTML in state
          },
        },
      );
    }
  }, [debouncedFields, opened, onOpenPreview]);

  return (
    <div>
      <Tooltip
        label="You can see the preview after you fill the form"
        disabled={enabled}
        maw={220}
        multiline
      >
        <Button
          leftSection={<EyeIcon />}
          size="xs"
          variant="outline"
          onClick={toggle}
          loading={isOpening}
          disabled={!enabled}
        >
          Preview Your Email
        </Button>
      </Tooltip>

      <Collapse in={opened}>
        <TypographyStylesProvider
          classNames={{ root: classes.preview }}
          dangerouslySetInnerHTML={{
            __html: sanitize(previewContent ?? 'Loading preview...'),
          }}
        />
      </Collapse>
    </div>
  );
};

export default Preview;
