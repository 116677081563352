import { object as ST, string, optional, size } from 'superstruct';
import { fileList, message, required, requiredDependsOn } from '@/utils/inputValidation';

import { useUnifiedApiPostSupport } from './services/postSupport';

import type { OnSubmitFunc } from '@/types/form';
import type { SupportFormFields, SupportResponse, SupportType } from './types';
import type { FetchError } from '@/types/api';

interface UseSupportFormProps {
  onSuccess?: (data: SupportResponse) => void;
  onError?: (error: FetchError) => void;
  type?: SupportType;
  relationship?: string;
}

export const useSupportForm = ({
  onSuccess,
  onError,
  type,
  relationship,
}: UseSupportFormProps) => {
  const { isPending: isPostingUnifiedApiRfi, mutate: sendUnifiedApiEmail } = useUnifiedApiPostSupport(type);

  const onSubmitUnifiedApi: OnSubmitFunc<SupportFormFields> = (rawFormData, setGeneralError, setSuccessMessage) => {
    const formData = new FormData();

    formData.append('request', JSON.stringify({
      name: rawFormData.subject,
      description: rawFormData.description,
      due_at: rawFormData.due_date,
      ...(relationship ? { [relationship]: [+rawFormData.ticket_id] } : {}),
      ...(rawFormData.subclient_id ? { clients: [+rawFormData.subclient_id] } : {}),
    }));

    Object.entries(rawFormData).forEach(([key, value]) => {
      if (value && key === 'attachment') {
        [...rawFormData.attachment].forEach(((att) => { formData.append('file', att); }));
      }
    });

    sendUnifiedApiEmail(
      formData,
      {
        onSuccess: (data) => {
          setSuccessMessage('Request sent successfully');
          onSuccess?.(data);
        },
        onError: (error) => {
          setGeneralError(error);
          onError?.(error);
        },
      },
    );
  };

  const schema = ST({
    subject: required(string(), 'Subject is required'),
    otherSubject: requiredDependsOn('subject', 'Other'),
    description: message(size(string(), 3, Infinity), 'Description should have at least 3 characters'),
    due_date: required(string(), 'Due Date is required'),
    attachment: optional(fileList()),
    to: required(string(), ''),
    ticket_id: optional(string()),
    subclient_id: optional(string()),
  });

  return {
    isPostingRfi: isPostingUnifiedApiRfi,
    schema,
    onSubmit: onSubmitUnifiedApi,
    sendEmail: sendUnifiedApiEmail,
  };
};
