import { Box, NumberInput } from '@mantine/core';

import BaseInput from '@/components/content/form/components/BaseInput';
import At from './At';

import { parseMonthly, stringifyMonthly } from './parsers';

interface MonthlyProps {
  onChange: (value: string | null) => void;
  error?: string;
  value: string;
}

const Monthly = ({ value, error, onChange }: MonthlyProps) => {
  const parsed = parseMonthly(value);

  const onFrequencyChange = (val: number | string) => {
    onChange(val ? stringifyMonthly(parsed?.minutes, parsed?.hours, [val], parsed?.timezone) : null);
  };

  return (
    <Box mt={10}>
      <BaseInput
        element={NumberInput}
        name="value"
        label="On Day"
        description="Max 31 days"
        value={parsed?.days[0]}
        error={error}
        size="xs"
        onChange={onFrequencyChange}
        min={1}
        max={31}
        clampBehavior="strict"
        allowNegative={false}
        allowDecimal={false}
      />

      <At
        parsed={parsed}
        onChange={(minute, hour, timezone) => (
          onChange(stringifyMonthly(minute, hour, parsed?.days as Array<string | number>, timezone))
        )}
        error={error}
      />
    </Box>
  );
};

export default Monthly;
