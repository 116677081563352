import { Group, NumberInput, Tooltip } from '@mantine/core';
import { Controller, useFieldArray } from 'react-hook-form';

import MercuryNativeSelect from '@/components/content/form/components/NativeSelect';

import type { FieldsetProps } from '../types';

import InfoIcon from '@/assets/icons/content/info.svg';

interface ConditionsProps extends FieldsetProps {
}

const Conditions = ({ register, control }: ConditionsProps) => {
  const { fields } = useFieldArray({
    control,
    name: 'conditions',
  });

  return (
    <fieldset>
      <legend>
        <Group justify="space-between" align="center" component="span">
          <span>Conditions<sup>*</sup></span>

          <Tooltip label="Beta version is limited to number of hits">
            <span>
              <InfoIcon width={17} height={17} />
            </span>
          </Tooltip>
        </Group>
      </legend>

      {fields.map((field, index) => (
        <Group wrap="nowrap" gap={5} key={field.id}>
          <MercuryNativeSelect
            register={register}
            name={`conditions.${index}.type` as any}
            // HARDCODED for now since we only have one condition but will move to a choices endpoint
            data={[{ label: 'Number of hits since last triggered', value: 'hits_since_last_run' }]}
            size="xs"
            styles={{ root: { flex: 1 } }}
          />

          <MercuryNativeSelect
            register={register}
            name={`conditions.${index}.operator` as any}
            // HARDCODED for now since we only have one condition but will move to a choices endpoint
            data={[{ label: 'more than', value: '>=' }]}
            size="xs"
          />

          <Controller
            name={`conditions.${index}.value` as any}
            control={control}
            render={({ field, fieldState }) => (
              <NumberInput
                size="xs"
                placeholder="Value"
                error={!!fieldState.error?.message}
                data-testid={`conditions.${index}.value`}
                value={field.value}
                min={1}
                onChange={(val) => field.onChange(`${val}`)}
                allowNegative={false}
                allowDecimal={false}
              />
            )}
          />
        </Group>
      ))}
    </fieldset>
  );
};

export default Conditions;
