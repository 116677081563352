import { useQuery } from '@tanstack/react-query';
import { settingsKeys } from './keys';
import { fetchData } from '@/lib/fetch';

interface NotificationsQuery {
  total: number;
  data: Array<Notification>;
}

export const useGetNotificationRules = <T = NotificationsQuery>(
  select?: (data: NotificationsQuery) => T,
  enabled?: boolean,
) => useQuery({
    queryKey: settingsKeys.notificationRules(),
    queryFn: () => fetchData({ endpoint: 'mercury/rules/' }),
    select,
    enabled,
  });
