import { useQueryClient } from '@tanstack/react-query';
import produce from 'immer';

import { authKeys } from '@/features/auth/services/keys';
import type { TokenInfo, UserInfo } from '@/features/auth/types';

export const useAuthInfoMutation = () => {
  const queryClient = useQueryClient();

  const setUserInfo = (newData: Partial<UserInfo>) => {
    queryClient.setQueryData<Partial<UserInfo>>(authKeys.userinfo(), (oldData) => (
      produce(oldData, (draftState) => ({
        ...draftState,
        ...newData,
      }))
    ));
  };

  const setTokenInfo = (newData: Partial<TokenInfo>) => {
    queryClient.setQueryData<Partial<TokenInfo>>(authKeys.tokeninfo(), (oldData) => (
      produce(oldData, (draftState) => ({
        ...draftState,
        ...newData,
      }))
    ));
  };

  return {
    setUserInfo,
    setTokenInfo,
  };
};
