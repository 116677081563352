import { useQuery } from '@tanstack/react-query';
import { fetchData } from '@/lib/fetch';
import { authKeys } from '@/features/auth/services/keys';
import type { UserInfo, TokenInfo } from '@/features/auth/types';

export const useUserInfo = () => (
  useQuery<UserInfo>({
    queryKey: authKeys.userinfo(),
    queryFn: () => fetchData({
      endpoint: 'userinfo',
      error: 'Couldn\'t verify user',
    }),
    meta: {
      avoidAuthRedirection: true,
    },
  })
);

export const useTokenInfo = () => (
  useQuery<TokenInfo>({
    queryKey: authKeys.tokeninfo(),
    queryFn: () => fetchData({
      endpoint: 'tokeninfo',
      error: 'Couldn\'t verify user',
    }),
    meta: {
      avoidAuthRedirection: true,
    },
  })
);
