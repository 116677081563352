import { superstructResolver } from '@hookform/resolvers/superstruct';
import { object as ST, string, optional, array } from 'superstruct';

import Query from './sections/Query';
import Form from '@/components/content/form/Form';
import Conditions from './sections/Conditions';
import Schedule from './sections/schedule/Schedule';
import Actions from './sections/Actions';
import MercuryTextInput from '@/components/content/form/components/TextInput';

import { required } from '@/utils/inputValidation';

import type { NotificationSubmit, NotificationSystemFormFields } from './types';
import Preview from './sections/Preview';

interface NotificationSystemModalProps {
  data: Partial<NotificationSystemFormFields>;
  submitText: string;
  onSubmit: NotificationSubmit;
  isSubmitting: boolean;
  closeModal: VoidFunction;
}

const NotificationSystemModal = ({
  data,
  submitText,
  isSubmitting,
  onSubmit,
  closeModal,
}: NotificationSystemModalProps) => {
  const conditionSchema = ST({
    type: required(string(), 'Type is required'),
    operator: required(string(), 'Operator is required'),
    value: required(string(), 'Value is required'),
  });

  const actionsSchema = ST({
    type: required(string(), 'Type is required'),
    values: ST({
      email: required(string(), 'Email is required'),
      subject: optional(string()),
    }),
  });

  const schema = ST({
    name: required(string(), 'Name is required'),
    query: required(string(), 'Query is required'),
    schedules: required(string(), 'Schedule is required'),
    conditions: array(conditionSchema),
    actions: array(actionsSchema),
  });

  return (
    <Form<NotificationSystemFormFields>
      onSubmit={onSubmit}
      submitButton={{
        text: submitText,
        loading: isSubmitting,
      }}
      cancelButton={{
        text: 'Cancel',
        onCancel: closeModal,
      }}
      fields={{ actions: 'Actions' }}
      options={{
        resolver: superstructResolver(schema),
        defaultValues: {
          name: data.name,
          query: data.query,
          schedules: data.schedules ?? '',
          conditions: data.conditions ?? [
            {
              type: 'hits_since_last_run',
              operator: '>=',
              value: '',
            },
          ],
          actions: data.actions ?? [
            {
              type: 'notify_email',
            },
          ],
        },
      }}
    >
      {(methods) => (
        <>
          <MercuryTextInput
            label="Let us know how you want to be notified about this"
            register={methods.register}
            name="name"
            placeholder="Name"
            error={methods.formState.errors.name?.message}
            size="xs"
          />

          <Query {...methods} />

          <Conditions {...methods} />

          <Schedule {...methods} />

          <Actions {...methods} />

          <Preview fields={methods.watch()} enabled={methods.formState.isValid} />
        </>
      )}
    </Form>
  );
};

export default NotificationSystemModal;
