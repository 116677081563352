import { useAuth } from '@/hooks/useAuth';
import { createContext, useMemo, PropsWithChildren } from 'react';

import type { Permissions } from '../types/permissions';

type MercuryPermissionsContextReturn = {
  hasAccess: (permissions: Permissions, operator?: 'AND' | 'OR') => boolean;
};

export const MercuryPermissions = createContext<MercuryPermissionsContextReturn | null>(null);

export const MercuryPermissionsProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const {
    tokenInfo: { entitlements, role } = {},
    tokenInfoQuery: { isSuccess },
  } = useAuth();

  const hasRoles = (allowedRoles: Permissions['roles']) => (
    allowedRoles && role ? allowedRoles.indexOf(role) !== -1 : false
  );

  const hasEntitlements = (requiredModules: Permissions['modules']) => (
    requiredModules ? requiredModules.some((module) => entitlements?.includes(module)) : false
  );

  const hasAccess = (
    { modules, roles }: Permissions,
    operator: 'AND' | 'OR' = 'AND',
  ) => {
    const hasRequiredModules = modules ? hasEntitlements(modules) : true;
    const hasAllowedRoles = roles ? hasRoles(roles) : true;

    if (operator === 'AND') {
      return hasRequiredModules && hasAllowedRoles;
    }

    return hasRequiredModules || hasAllowedRoles;
  };

  const value = useMemo(() => ({
    hasAccess,
  }), [isSuccess]);

  return (
    <MercuryPermissions.Provider value={value}>
      {children}
    </MercuryPermissions.Provider>
  );
};
