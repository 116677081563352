import { useCallback } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Box, Stack, Text } from '@mantine/core';

// Components
import LoginForm from '../components/LoginForm';
import Unauthorized from '../components/Unauthorized';
import SamlRedirect from '../components/SamlRedirect';
import WelcomeMessage from '../components/WelcomeMessage';
import Title from '@/components/content/title/Title';

// Hooks
import { useModal } from '@/hooks/useModal';
import { useSignIn } from '../hooks/useSignIn';

import classes from './Auth.module.css';

const Login = () => {
  const {
    loginPageState,
    signIn,
    nodeRef,
    isLoginReady,
    authInfo,
    isGettingType,
    loadingSignIn,
    onCancelOrBack,
    redirectParam,
    onUnauthorizedPrecheck,
  } = useSignIn();

  const { Modal, modalProps } = useModal({
    initialOpenState: loginPageState && !!loginPageState.modal_text,
    onClose: () => window.history.replaceState({}, document.title),
  });

  const { username, auth_type: authType } = authInfo || {};

  const renderMessage = useCallback(
    () => (
      <WelcomeMessage
        username={username}
        onClick={onCancelOrBack}
        isLoginReady={isLoginReady}
        showBackButton={authInfo?.auth_type !== 'SAML'}
      />
    ),
    [authInfo],
  );

  return (
    <Stack align="center" justify="center" classNames={{ root: classes.wrapper }}>
      <div className={classes.content}>
        <Title order={1}>
          <Box component="span" c="var(--active-color)">Mercury</Box>
          <Title component="span" size="h3">Threat Intelligence Portal</Title>
        </Title>

        {(!isGettingType || loginPageState?.avoidPrecheck) && (
          <SwitchTransition>
            <CSSTransition
              key={username ? 'login' : 'unauthorized'}
              nodeRef={nodeRef}
              timeout={200}
              classNames={{
                enter: classes.enter,
                enterActive: classes.enterActive,
                exit: classes.exit,
                exitActive: classes.exitActive,
              }}
              className={classes.loginFade}
            >
              <Box mt={15} ref={nodeRef}>
                {isLoginReady ? (
                  <div>
                    {authType === 'SAML' ? (
                      <SamlRedirect
                        username={username as string}
                        renderMessage={renderMessage}
                        onCancel={onCancelOrBack}
                      />
                    ) : (
                      <LoginForm
                        username={username}
                        signIn={signIn}
                        isSigningIn={loadingSignIn}
                        renderMessage={renderMessage}
                        tfa={authType === '2FA'}
                      />
                    )}
                  </div>
                ) : (
                  <Unauthorized onUnauthorizedPrecheck={onUnauthorizedPrecheck} redirectParam={redirectParam} />
                )}
              </Box>
            </CSSTransition>
          </SwitchTransition>
        )}
      </div>

      <Modal {...modalProps} title={loginPageState?.modal_title} size="500px">
        <Text size="xl" mt={15}>{loginPageState?.modal_text}</Text>
      </Modal>
    </Stack>
  );
};

export default Login;
