import { Center } from '@mantine/core';

import Linkv2 from '@/components/content/link/Linkv2';

import { useNotificationsMarkAllAsReadMutation } from '../services/markAllAsRead';
import { useUserId } from '@/hooks/useUserId';

interface NotificationsReadAllProps {
  refetch: () => void;
}

const NotificationsReadAll = ({ refetch }: NotificationsReadAllProps) => {
  const userId = useUserId();
  const { mutate: markAllAsReadMutation } = useNotificationsMarkAllAsReadMutation(userId);

  const onClick = () => {
    // Mark all the issues as read in background
    markAllAsReadMutation({}, {
      onSuccess: () => {
        // TODO - Invalidate cache to update the notifications counter
        refetch();
      },
      onError: (error) => { console.error(error); },
    });
  };

  return (
    <Center mt={15}>
      <Linkv2 as="button" type="button" onClick={onClick}>Mark all as read</Linkv2>
    </Center>
  );
};

export default NotificationsReadAll;
