import { Group, Text, Tooltip } from '@mantine/core';

import BaseResult from '@/client/features/search/components/BaseResult';
import LocationIcon from '@/client/features/iconmap/LocationIcon';
import IconMap from '@/client/features/iconmap/IconMap';
import List from '@/components/content/list/List';
import IssueWrapperV2 from '@/client/features/issue/components/IssueWrapper';

import { MercuryTrackersIds, mercuryTrackers } from '@/constants/mercuryPages';
import { formatIsoDate } from '@/utils/date';
import { objectHasValues } from '@/utils';
import { aggregateFromMetisRelationship } from '../../../utils/aggregateFromMetisRelationship';
import { getContextData } from '../../../utils/context';

import type { MetisProvider } from '../types';
import { INDUSTRY_ICON_MAP } from '@/client/features/iconmap/icons/industry';
import { providersDrawer } from '../config/providers.drawer';

const ProvidersSearchResult = ({ data }: { data: MetisProvider }) => {
  const locations = aggregateFromMetisRelationship(data.raw_intel, 'locations');
  const industries = aggregateFromMetisRelationship(data.raw_intel, 'industries');
  const ta = aggregateFromMetisRelationship(data.raw_intel, 'threat_actors');
  const vulnerabilities = aggregateFromMetisRelationship(data.raw_intel, 'vulnerabilities');
  const malwareKits = aggregateFromMetisRelationship(data.raw_intel, 'malware_kits');
  const context = getContextData({ vulnerabilities, malware_kits: malwareKits, threat_actors: ta });

  return (
    <BaseResult
      title={{ id: data.id, name: data.name }}
      content={data.description}
      tracker={mercuryTrackers[MercuryTrackersIds.providers]}
      drawerContent={(
        <IssueWrapperV2
          top={providersDrawer}
          data={data}
          id={data.id}
          removePadding
        />
      )}
      footer={[
        <Text size="sm">
          <Text fw="bold" span>Last Updated:</Text> {formatIsoDate(data.updated_at)}
        </Text>,
        objectHasValues(locations)
        && (
          <Group gap={5}>
            <Text fw="bold" size="sm" span>Locations:</Text>
            <LocationIcon locations={locations} id={data.id} max={2} layout="small" />
          </Group>
        ),
        objectHasValues(industries)
        && (
          <Group gap={5}>
            <Text fw="bold" size="sm" span>Industries:</Text>
            <IconMap
              id={data.id}
              map={industries}
              icons={INDUSTRY_ICON_MAP}
              showTooltip
              layout="small"
              renderLabelWithoutIcon={(label) => label}
              max={3}
            />
          </Group>
        ),
        context.length > 0
        && (
          <Group gap={5} align="center">
            <Text fw="bold" size="sm" span>Related Catalogs:</Text>
            <List
              gap={10}
              direction="row"
              align="center"
              style={{ '--svg-icon-color': 'var(--icon-column-stroke)' }}
              items={context}
              renderItem={([name, count, icon]) => (
                <Tooltip label={name}>
                  <Group align="center" gap={5}>{icon} <Text size="sm" span>{count}</Text></Group>
                </Tooltip>
              )}
            />
          </Group>
        ),
      ]}
    />
  );
};

export default ProvidersSearchResult;
