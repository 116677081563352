import { useEffect, type PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import Loading from '@/components/content/loading/Loading';

// // Hooks
import { useAdminRedirect } from '../hooks/useAdminRedirection';
import { useAuth } from '@/hooks/useAuth';
import { useSession } from '@/context/SessionContext';

// Misc
import { isServiceUser } from '@/utils/usersRole';

const Auth = ({ children }: PropsWithChildren<{}>) => {
  const location = useLocation();
  const { isInAdminDomain, hasAdminRedirect, returnToMercuryOrigin, redirectAdmin } = useAdminRedirect();
  const { tokenInfo, tokenInfoQuery } = useAuth();
  const { isSuccess, isLoading, isError } = tokenInfoQuery;
  const { startSession } = useSession();

  useEffect(() => {
    if (isSuccess) {
      startSession();
    }
  }, [isSuccess]);

  if (isLoading) {
    return <Loading text="Verifying" height="100vh" />;
  }

  if (isError) {
    if (isInAdminDomain()) {
      returnToMercuryOrigin('login');
      return null;
    }

    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (isServiceUser(tokenInfo.role)) {
    return (
      <Navigate
        to="/login"
        state={{
          modal_title: 'Sorry',
          modal_text: `
          Your account doesn't have access to Mercury.
          Please try to login with a valid account or
          contact your dedicated Customer Success Manager if you need assistance.
        `,
        }}
      />
    );
  }

  if (tokenInfo.force_2fa && !tokenInfo.enabled_2fa) {
    returnToMercuryOrigin(`activate2fa?username=${encodeURIComponent(tokenInfo.sub)}`);
    return null;
  }

  if (hasAdminRedirect(tokenInfo)) {
    redirectAdmin();
    return null;
  }

  return children;
};

export default Auth;
