export const ACCESS_ROLES = {
  ROLE_ANALYST: 'ROLE_ANALYST',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_CLIENT_ANALYST: 'ROLE_CLIENT_ANALYST',
  ROLE_CLIENT_USER: 'ROLE_CLIENT_USER',
  ROLE_SERVICE_USER: 'ROLE_CLIENT_SERVICE',
} as const;

export const ACCESS_MODULES = {
  SERVICE_PREMIUM: 'SERVICE_PREMIUM',
  SERVICE_BASE: 'SERVICE_BASE',
  MODULE_VULN: 'MODULE_VULN',
  MODULE_ADVTHREATINT: 'MODULE_ADVTHREATINT',
  MODULE_IDENTITY: 'MODULE_IDENTITY',
  MODULE_ALERTS_MGMT: 'MODULE_ALERTS_MGMT',
  MODULE_CUSTOMREPORT: 'MODULE_CUSTOMREPORT',
  MODULE_EASM: 'MODULE_EASM',
  MODULE_ANALYTICS: 'MODULE_ANALYTICS',
  MODULE_SUPPLYCHAIN: 'MODULE_SUPPLYCHAIN',
  MODULE_NOTIFICATIONS: 'MODULE_NOTIFICATIONS',
} as const;

const MODULES = {
  intelligence: {
    modules: [
      ACCESS_MODULES.SERVICE_PREMIUM,
      ACCESS_MODULES.MODULE_ADVTHREATINT,
    ],
  },
  alertsmanagement: {
    roles: [ACCESS_ROLES.ROLE_ANALYST],
    modules: [
      ACCESS_MODULES.MODULE_ALERTS_MGMT,
    ],
  },
  ransomware: {
    modules: [
      ACCESS_MODULES.SERVICE_PREMIUM,
      ACCESS_MODULES.MODULE_ADVTHREATINT,
    ],
  },
  easm: {
    modules: [
      ACCESS_MODULES.MODULE_EASM,
    ],
  },
  identitypanel: {
    modules: [
      ACCESS_MODULES.MODULE_IDENTITY,
    ],
  },
  analyticsdashboard: {
    modules: [
      ACCESS_MODULES.MODULE_ANALYTICS,
    ],
  },
  supplychain: {
    modules: [
      ACCESS_MODULES.MODULE_SUPPLYCHAIN,
    ],
  },
} as const;

const FEATURES = {
  notifications: {
    modules: [
      ACCESS_MODULES.MODULE_NOTIFICATIONS,
    ],
  },
  signals: {
    modules: [
      ACCESS_MODULES.SERVICE_PREMIUM,
    ],
  },
};

const CATALOGS = {
  attcks: {
    modules: [
      ACCESS_MODULES.SERVICE_PREMIUM,
      ACCESS_MODULES.MODULE_ADVTHREATINT,
    ],
  },
  threatactors: {
    modules: [
      ACCESS_MODULES.SERVICE_PREMIUM,
      ACCESS_MODULES.MODULE_ADVTHREATINT,
    ],
  },
  malwarekits: {
    modules: [
      ACCESS_MODULES.SERVICE_PREMIUM,
      ACCESS_MODULES.MODULE_ADVTHREATINT,
    ],
  },
  sources: {
    modules: [
      ACCESS_MODULES.SERVICE_PREMIUM,
      ACCESS_MODULES.MODULE_ADVTHREATINT,
    ],
  },
  providers: {
    modules: [
      ACCESS_MODULES.SERVICE_PREMIUM,
      ACCESS_MODULES.MODULE_ADVTHREATINT,
    ],
  },
  personas: {
    modules: [
      ACCESS_MODULES.SERVICE_PREMIUM,
      ACCESS_MODULES.MODULE_ADVTHREATINT,
    ],
  },
  vulnerabilities: {
    modules: [
      ACCESS_MODULES.SERVICE_PREMIUM,
      ACCESS_MODULES.MODULE_ADVTHREATINT,
      ACCESS_MODULES.MODULE_VULN,
    ],
  },
} as const;

export {
  MODULES,
  CATALOGS,
  FEATURES,
};
