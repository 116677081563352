import { type ChangeEvent, useMemo } from 'react';
import { NativeSelect } from '@mantine/core';
import { type CalendarDate, getLocalTimeZone, today } from '@internationalized/date';
import { useDateFormatter } from 'react-aria';
import type { RangeCalendarState } from 'react-stately';

import classes from './DatePicker.module.css';

interface YearDropdownProps {
  state: RangeCalendarState;
}

type Years = Array<{
  value: CalendarDate;
  formatted: string;
}>;

const now = today(getLocalTimeZone());

const NUMBER_OF_YEARS = 10;

const YearDropdown = ({
  state,
}: YearDropdownProps) => {
  const formatter = useDateFormatter({
    year: 'numeric',
    timeZone: state.timeZone,
  });

  const formattedYears = useMemo(() => {
    const years: Years = [];

    // Format 10 years on each side of the current year according to the current locale and calendar system.
    for (let i = -NUMBER_OF_YEARS; i <= 0; i += 1) {
      const date = now.add({ years: i }).set({ month: state.focusedDate.month, day: 0 });

      years.push({
        value: date,
        formatted: formatter.format(date.toDate(state.timeZone)),
      });
    }

    return years;
  }, [state.focusedDate.month]);

  const data = useMemo(() => formattedYears.map((year, i) => ({
    value: `${i}`, label: year.formatted,
  })), []);

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const index = +e.target.value;
    const date = formattedYears[index].value;

    state.setFocusedDate(date);
  };

  const getValue = () => {
    const index = formattedYears.findIndex((year) => year.formatted === state.focusedDate.year.toString());

    return String(index);
  };

  return (
    <NativeSelect
      aria-label="Years"
      value={getValue()}
      onChange={onChange}
      data={data}
      size="xs"
      classNames={{ input: classes.datePickerSelect }}
    />
  );
};

export default YearDropdown;
