import { useEffect } from 'react';
import { ActionIcon } from '@mantine/core';

import { removeSpecialCharsAndSpaces } from '@/utils/string';

import PrintIcon from '@/assets/icons/content/print.svg';
import MercuryButton from './Button';

interface PrintIconProps {
  documentTitle: Array<string>;
  asButton?: boolean;
}

const PrintButton = ({ documentTitle, asButton }: PrintIconProps) => {
  const print = () => {
    window.requestAnimationFrame(() => {
      // requestAnimationFrame is important because window.print is an async function
      window.print();
    });
  };

  useEffect(() => {
    const originalTitle = document.title;

    window.addEventListener('beforeprint', () => {
      document.title = documentTitle.map((srt) => removeSpecialCharsAndSpaces(srt)).join('_');
    });

    window.addEventListener('afterprint', () => {
      document.title = originalTitle;
    });

    return () => {
      window.removeEventListener('beforeprint', () => {
        document.title = originalTitle;
      });

      window.removeEventListener('afterprint', () => {
        document.title = originalTitle;
      });
    };
  }, []);

  return asButton ? (
    <MercuryButton
      rightSection={<PrintIcon width={17} height={17} />}
      onClick={print}
    >
      Print
    </MercuryButton>
  ) : (
    <ActionIcon
      size={30}
      variant="subtle"
      aria-label="Print"
      onClick={print}
    >
      <PrintIcon />
    </ActionIcon>
  );
};

export default PrintButton;
