import { type ChangeEvent, useState } from 'react';
import { TextInput, type TextInputProps } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';

interface DebouncedInputProps extends TextInputProps {
  debounceTime?: number;
  setValue: (value: string) => void;
}

const DebouncedInput = ({
  value: initialValue,
  setValue,
  debounceTime = 500,
  ...inputProps
}: DebouncedInputProps) => {
  const [{ localValue, prevProvidedValue }, setState] = useState({
    localValue: initialValue,
    prevProvidedValue: initialValue,
  });

  if (initialValue !== prevProvidedValue) {
    setState({
      localValue: initialValue,
      prevProvidedValue: initialValue,
    });
  }

  const debouncedSetValue = useDebouncedCallback((newValue: string) => setValue(newValue), debounceTime);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setState((prev) => ({
      localValue: newValue,
      prevProvidedValue: prev.prevProvidedValue, // Keep the previous value
    }));

    debouncedSetValue(newValue); // Debounce update to parent
  };

  return (
    <TextInput
      value={localValue}
      onChange={onChange}
      {...inputProps}
    />
  );
};

export default DebouncedInput;
