import { useCallback, useRef } from 'react';
import {
  Box, Flex, Group, ScrollArea, Stack, UnstyledButton,
} from '@mantine/core';
import { type DateValue, type RangeCalendarProps, useLocale, useRangeCalendar } from 'react-aria';
import { useRangeCalendarState } from 'react-stately';

import PresetComp from './Preset';
import MonthDropdown from './MonthDropdown';
import YearDropdown from './YearDropdown';
import CalendarGrid from './CalendarGrid';

import { createCalendar } from './createCalendar';
import { getEntries } from '@/utils';

import type { Presets, RangeValue } from './types';

import ChevronLeft from '@/assets/icons/content/chevron-left.svg';
import ChevronRight from '@/assets/icons/content/chevron-right.svg';

import classes from './DatePicker.module.css';

interface RangeCalerndarCompProps {
  presets?: Presets;
  rangeCalendarProps: RangeCalendarProps<DateValue>;
  onChange: ((value: string | RangeValue) => void);
  disableRelativeDates?: boolean;
}

const RangeCalendar = ({
  presets = {},
  rangeCalendarProps,
  onChange,
  disableRelativeDates = false,
}: RangeCalerndarCompProps) => {
  const { locale } = useLocale();
  const state = useRangeCalendarState({
    ...rangeCalendarProps,
    locale,
    createCalendar,
  });
  const ref = useRef(null);
  const { calendarProps } = useRangeCalendar(rangeCalendarProps, state, ref);
  const setNewPresetRange = useCallback((value: string) => {
    const preset = presets[value];

    if (preset) {
      onChange(disableRelativeDates ? preset.rangeDateValue() : value);
    }
  }, []);

  const presetsArray = getEntries(presets);

  return (
    <Flex gap={5} classNames={{ root: classes.datePickerCalendarContainer }}>
      {presetsArray.length > 0 && (
        <ScrollArea.Autosize
          mah={300}
          mx="auto"
          scrollbarSize={2}
          classNames={{ root: classes.datePickerCustom }}
        >
          <Stack gap={0}>
            {getEntries(presets).map(([key, preset]) => (
              <PresetComp
                key={`preset-${key}`}
                preset={preset}
                setNewRange={setNewPresetRange}
              />
            ))}
          </Stack>
        </ScrollArea.Autosize>
      )}

      <Box
        {...calendarProps}
        ref={ref}
        className={`${classes.datePickerCustom} ${classes.stretch}`}
      >
        <Group align="center" justify="space-between">
          <UnstyledButton onClick={state.focusPreviousPage}>
            <ChevronLeft />
          </UnstyledButton>
          <MonthDropdown state={state} />
          <YearDropdown state={state} />
          <UnstyledButton onClick={state.focusNextPage}>
            <ChevronRight />
          </UnstyledButton>
        </Group>
        <CalendarGrid state={state} />
      </Box>
    </Flex>
  );
};

export default RangeCalendar;
