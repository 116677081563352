import type { PropsWithChildren } from 'react';
import { Flex, FlexProps, Group } from '@mantine/core';

import Title from '@/components/content/title/Title';

import type { ModuleDef } from '../types/modulesv2';

import classes from './IssueModule.module.css';

interface ModuleWrapperProps<TData> extends FlexProps, Omit<ModuleDef<TData>, 'submodules'> {
  expand?: boolean;
}

const ModuleWrapper = <TData extends unknown>({
  title,
  expand,
  children,
  removeBackground,
  removePadding,
  removeMargin,
  icon,
  id,
  noPrint,
  ...flexProps
}: PropsWithChildren<ModuleWrapperProps<TData>>) => (
  <div
    id={id}
    className={`
      ${classes.moduleContainer}
      ${removeMargin ? classes.removeMargin : ''}
      ${noPrint ? 'noPrint' : ''}
      ${expand ? classes.expand : ''}
      `}
  >
    {title && (
      <Group classNames={{ root: classes.moduleTitle }} gap={5}>
        <Title order={2}>{title}</Title>
        {icon}
      </Group>
    )}

    <Flex
      classNames={{ root: `
      ${classes.module}
      ${expand ? classes.expand : ''}
      ${removePadding ? classes.removePadding : ''}
      ${removeBackground ? classes.removeBackground : ''}
    ` }}
      gap={15}
      direction="column"
      {...flexProps}
    >
      {children}
    </Flex>
  </div>
  );

export default ModuleWrapper;
