import { getValues } from '@/utils';
import { useAuth } from './useAuth';

export const useMSSP = () => {
  const auth = useAuth();

  const subclients = getValues(auth.tokenInfo.subclients ?? {});
  const isMSSP = subclients.length > 0;
  const isSubclient = auth.tokenInfo.is_subclient;

  return {
    isMSSP,
    isSubclient,
    subclients,
    subclientsObj: auth.tokenInfo.subclients,
  };
};
