import { Stack, Text } from '@mantine/core';

import RiskIndicators from '../../riskindicators/RiskIndicators';
import List from '@/components/content/list/List';
import IconMap from '../../iconmap/IconMap';
import Pill from '@/components/content/pills/Pill';

import { createHelper } from '../../issue/utils/helper';
import { formatIsoDate } from '@/utils/date';
import { getContextData } from '../../catalogs/utils/context';
import { getEntries } from '@/utils';
import { CATEGORY_ICONS } from '../../iconmap/icons/intel_category';

import type { Module } from '../../issue/types/modulesv2';
import type { NormalizedSignal } from '@/services/unifiedapi/signals';

const helper = createHelper<NormalizedSignal>();

export const signalDrawer: Array<Module<NormalizedSignal>> = [
  {
    submodules: [
      helper.accessor((data) => data.id, {
        element: ({ getValue }) => (
          <Stack gap={10} align="center" justify="center">
            <Text fw="bold" ta="center">{getValue()}</Text>
            <Text size="sm">Signal</Text>
          </Stack>
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => ({
        locations: getEntries(data.catalog.locations).map(([id, name]) => ({ id, name })),
        technologies: getEntries(data.catalog.technologies).map(([id, name]) => ({ id, name })),
        industry_sectors: getEntries(data.catalog.industry_sectors).map(([id, name]) => ({ id, name })),
      }), {
        title: 'Your Relevancy',
        element: RiskIndicators,
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.title, {
        title: 'Title',
      }),
      helper.accessor((data) => data.text, {
        title: 'AI Summary',
      }),
    ],
  },
  {
    direction: { base: 'column', s: 'row' },
    justify: 'space-around',
    gap: 20,
    submodules: [
      helper.accessor((data) => data.catalog.category?.[0], {
        title: 'Category',
        align: 'center',
        element: ({ getValue, id }) => (
          <IconMap
            id={id}
            icons={CATEGORY_ICONS}
            map={{ [getValue()]: getValue() }}
            renderLabelWithoutIcon={(label) => (
              <Pill
                component="span"
                bg="light-dark(var(--icon-column-stroke), var(--quo-blue)"
                color="var(--quo-white)"
                value={label}
              />
            )}
            showTooltip
          />
        ),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.ingested_at, {
        title: 'Ingested At',
        element: ({ getValue }) => formatIsoDate(getValue()),
      }),
      helper.accessor((data) => data.published_at, {
        title: 'Published At',
        element: ({ getValue }) => formatIsoDate(getValue()),
      }),
      helper.accessor((data) => data.updated_at, {
        title: 'Updated At',
        element: ({ getValue }) => formatIsoDate(getValue()),
      }),
      helper.accessor((data) => data.link, {
        title: 'Link',
      }),
      helper.accessor((data) => data.catalog.source, {
        title: 'Source',
        element: ({ getValue }) => getValue(),
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => getContextData(data.catalog), {
        title: 'Extracted Entities',
        element: ({ getValue }) => (
          <List
            items={getValue()}
            renderItem={([name, count]) => `${name} (${count})`}
          />
        ),
      }),
    ],
  },
];
